<template>
  <div class="content_box metar_box">
    <div style="width: 100%; height: 100%" id="wgybMap1"></div>
    <div class="op_layer title">
      <p>{{ actuality.title }}</p>
    </div>
    <div class="op_layer search">
      <ul class="element_Box1" :class="{Article: isshowfactList}">
        <li v-for="(item, index) of elementList" :key="index" class="element_list" :class="{Article_item:item.open}" @click="selElement(item)">
          <div class="article_title">
              <img class="element_img" :src="require(`@/assets/images/metar/${item.img}${item.open?'a':''}.jpg`)" alt="">
              <transition name="moveCartoon">
                <span v-show="isshowfactList">{{item.name}}</span>
              </transition>
          </div>
          <transition name="moveCartoon">
            <ol class="fact_list" type="circle" v-show="item.open && isshowfactList">
              <li v-for="(fact, sub) of item.list" :key="sub" :class="{Article_item_fact:fact.open}" @click.stop="selItemElement(fact,item)">
                <span>{{fact.name}}</span>
              </li>
            </ol>
          </transition>
        </li>
      </ul>
      <ul class="element_Box2">
        <li class="element_list" @click="showfactList">
            <img class="element_img" :src="require(`@/assets/images/metar/sk1.7${isshowfactList?'a':''}.jpg`)" alt="">
        </li>
      </ul>
    </div>

    <div class="op_layer sbttl" v-if="sbttl" style="background-color: #fff;">
      <span>单位: {{partUnit(elTypeItem.tid).dw}} </span>
      <img :src="sbttl" alt="" >
    </div>

    <div
      class="op_layer isSebantu"
      :class="{ openSebantu: isSebantu }"
      @click="sebantuOpen"
    >
      色斑图
    </div>
    <canvas id="canvasMap" style="display: none"></canvas>
    <van-popup v-model="facePlate" :overlay="false" position="bottom" :style="{ height: '43%' }">
      <div class="close_panel">
        <h3>{{weatherDataPredictCity}} 近24小时实况</h3>
        <!-- <van-radio-group @change="switchHorizontal" class="" v-model="GeDianY" direction="horizontal">
          <van-radio name="1">本省</van-radio>
          
          <van-radio name="2">全国</van-radio>
        </van-radio-group> -->
        <!-- <span>07-07 08:00</span> -->
        <van-icon @click="facePlate = false" name="arrow-down" color="#15AAEC" />
      </div>
      <div class="yscdan_box">
      <ul class="sijda" v-if="siteElement">
        <li @click="selElementMenu(item)" v-for="item of siteElementMenu" :key="item.key" :class="{activeElmenu:siteElement.key == item.key}">{{item.text}}</li>
      </ul>
      <div class="sijda-danwei">单位：{{weatherDataPredictUnit}}</div>
      </div>

      <div class="ec_data" ref="ECdata"></div>
      <p class="Tips">注：数据由实况数据服务产品自动生成，仅供参考。</p>
    </van-popup>
  </div>
</template>

<script>
import { gdybEc } from './echrts.js'
import { loadkriging, getColorGuide } from './metar.js'
import { getColor } from "@/views/WGYB/element.js"
import { Formats, getweektime } from "@/utils/common.js";
import { Get_DataQuery, GetDataSbtQuery, tqybweather } from './api.js'
import { drawCanvas, drawCard, drawCard1 } from "@/utils/drawSbt.js";

var mytdMap;
var mapLayerr; // 地图色斑图图层管理
var markers; // 站点
var rateChart;
export default {
  name: "wgyb",
  data() {
    return {
      facePlate:false,
      sbttl:null,
      overlayLoading:false,
      isSebantu: true,
      weatherDataPredictCity:'',
      weatherDataPredictUnit:'',
      isshowfactList:false,
      weatherData:{},
      siteElement:null,
      siteElementMenu:[
        { id:'qiwen',unit:'℃', text:'气温',key:'temperature' },
        { id:'jiangshui',unit:'mm', text:'降水',key:'rain1h' },
        { id:'fsfx',unit:'m/s', text:'风速风向',key:'windSpeed' },
        { id:'shidu',unit:'%', text:'湿度',key:'humidity' },
        { id:'qiya',unit:'hPa', text:'气压',key:'pressure' },
      ],
      siteActive: { id: 0, site: '全部' },
      elTypeItem: null, // 选中的要素
      elementList: [
        {
          name: "气温",
          img: "sk1.1",
          colorKey: "wendu",
          Elements:'1',
          tid: "TMP",
          open: false,
          list: [
            { value:1, name: "实时气温", open: false },
            // { value:'2', name: "今日最高温", open: false },
            // { value:'3', name: "今日最低温", open: false },
          ],
        },
        {
          name: "风",
          img: "sk1.3",
          colorKey: "fsfx",
          Elements:'9',
          tid: "EDA10",
          open: false,
          list: [
            { value: 1, name: "实时风速", open: false },
          ],
        },
        {
          name: "降水",
          img: "sk1.2",
          colorKey: "jiangshui",
          Elements:'0',
          tid: "ER03",
          open: false,
          list: [
            { value:1, name: "近1小时降水", open: false },
            { value:3, name: "近3小时降水", open: false },
            { value:6, name: "近6小时降水", open: false },
            { value:12, name: "近12小时降水", open: false },
            { value:24, name: "近24小时降水", open: false },
            { value:48, name: "近48小时降水", open: false },
            { value:'08时以来降水', name: "08时以来降水", open: false },
            { value:'20时以来降水', name: "20时以来降水", open: false },
          ],
        },
        {
          name: "湿度",
          img: "sk1.4",
          colorKey: "shidu",
          Elements:'10',
          tid: "ERH",
          open: false,
          list: [
            { value: 1, name: "实时湿度", open: false },
          ],
        },

        {
          name: "气压",
          img: "sk1.5",
          colorKey: "qiya",
          Elements:'11',
          tid: "qiya",
          open: false,
          list:[
            { value: 1, name: "实时气压", open: false },
          ]
        },
        // {
        //   name: "能见度",
        //   img: "sk1.6",
        //   colorKey: "njd",
        //   Elements:'6',
        //   tid: "njd",
        //   open: false,
        //   list: [
        //     { name: '实时能见度', value: 1, open: false },
        //   ],
        // },
      ],
      predictionItem:[],
      queryData: {},
      actuality: {
        title: "",
        elementText: "实时气温",
        Isdowm: false,
      },
    };
  },
  created(){
    this.getQueryDaate()
  },
  mounted() {
    setTimeout(() => {
      this.mapCreat();
    }, 500);
  },
  methods: {

    // 获取时间
    getQueryDaate(){
      var date = new Date()
      /*** 如果当前分钟小于30，取上一小时数据 */
      if(date.getMinutes() < 30){
         date.setHours(date.getHours() - 1);
      }
      this.queryData.StartTime = Formats(new Date(date.getTime() - 60*60*1000))
      this.queryData.EndTime = Formats(date)
    },

    // 打开
    showfactList(){
      this.isshowfactList = !this.isshowfactList;
    },

    // 要素echatrs
    async getgdybFunEc(obj) {
      let code = obj.StationNo;
      this.$loading.show();
      const res = await tqybweather({stationid:code,_:new Date().getTime()})
      this.$loading.hide();
      if(res.data && Array.isArray(res.data.passedchart)){
        this.weatherData = res.data;
        // this.getelementDataEc()
        if(this.siteElement){
          this.selElementMenu(this.siteElement)
        }else{
          this.selElementMenu(this.siteElementMenu[0])
        }
      }
    },

    // 下拉中选择的要素
    selElementMenu(menu){
      this.siteElement = menu;
      // console.log('menu')
      
      this.getelementDataEc()
    },

    // 要素数据数据
    getelementDataEc(){
      if(!this.siteElement)return 
      if(!this.weatherData)return 
      let { unit,text, key, id } = this.siteElement;
      this.weatherDataPredictUnit = unit;
      this.weatherDataPredictCity = this.weatherData.predict.station.city;
      let params = {data:[],xData:[],unit,text,id};
      let fxData = []
      this.weatherData.passedchart.forEach(it=>{
        params.data.push(it[key]);
        params.xData.unshift(it.time);
        if(id === 'fsfx'){
          fxData.push(it.windDirection)
        }
      })
      if(id === 'jiangshui'){
        params.series = [
            {
              name: text,
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
            label: {
              show: true,
              position: 'top'
            },
              data: params.data
            },
        ]
      }else if(id ==='fsfx'){
        params.series =[
          {
            symbol: `image://${require("../../assets/images/fengxiangbiao.png")}`,
                symbolRotate: function(param, e) {
                  return fxData[e.dataIndex];
                },
                symbolSize: 15,
              label: {
                show: true,
                position: "top",
                color: "#4EBA99"
              },
              name: text,
              type: 'line',
              smooth: true,
              label: {
                show: true,
                position: 'top'
              },
              data: params.data,
              fxData:fxData
          }
        ]
      }else{
        params.series =[
          {
            name: text,
            type: 'line',
            smooth: true,
            label: {
              show: true,
              position: 'top'
            },
            data: params.data,
          }
        ]
      }
      this.gdybFunEc(params)
    },
    
    // 要素echatrs
    gdybFunEc(params) {
      if (rateChart != null && rateChart != "" && rateChart != undefined) {
        rateChart.dispose(); //销毁
      }
      var dom = this.$refs.ECdata;
      rateChart = gdybEc(dom, params);
    },

    // 色斑图开关
    sebantuOpen() {
      this.isSebantu = !this.isSebantu;
      if (this.isSebantu) {
        if(this.elTypeItem){
          let el = this.getSelElement(this.elTypeItem.list)
          this.selItemElement(el,this.elTypeItem)
        }
      } else {
        this.cleanMapLayerr();
        this.sbttl = null
      }
    },

    defineTime(obj) {
      const { value, Elements } = obj
      let data = {
        StartTime: '',
        EndTime: '',
        Elements: '',
        type: this.siteActive.id ? this.siteActive.id : 0,
      }
      this.getQueryDaate()
      if (value === '20时以来降水') {
        let date = new Date()
        let H = date.getHours() // 获取当前小时
        let start =
          H < 20
            ? new Date(date.getTime() - 1000 * 60 * 60 * (24 - 20 + H))
            : new Date().setHours(8)
        let end = H < 20 ? new Date() : new Date().setHours(20)
        data.EndTime = Formats(end)
        data.StartTime = Formats(start)
      } 
      else if (value === '08时以来降水') {
        let date = new Date()
        let H = date.getHours() // 获取当前小时
        let start = new Date().setHours(8)
        let end = H < 20 ? new Date() : new Date().setHours(20)
        data.EndTime = Formats(end)
        data.StartTime = Formats(start)
      } 
      else if (value === '2' || value === '3') {
        data.StartTime = Formats(new Date(this.queryData.StartTime))
        data.EndTime = Formats(new Date(this.queryData.EndTime))
      } 
      else {
        let date = new Date()
      /***如果当前分钟小于30，取上一小时数据 */
      if(date.getMinutes() < 30){
         date.setHours(date.getHours() - 1);
      }
        let H = date.getHours() // 获取小时
        let mm = date.getMinutes() // 获取分钟
        H = mm < 10 ? H - 1 : H // 结束时间为当前时间，又因当前分钟没超过10的话结束时间是前一小时
        let end = new Date(new Date().setHours(H)) // 获取到结束时间
        let start = new Date(end.getTime() - 1000 * 60 * 60 * value)
        data.EndTime = Formats(end)
        data.StartTime = Formats(start)
        // console.log('小时内', data.StartTime, '~', data.EndTime)
      }
      data.Elements = Elements // 获取要素类型
      if (Elements == 1 && value === '任意时段查询') {
        data.Elements = this.jyelmentActive.id
      }
      if (Elements == '1' && (value == '2' || value == '3'))
        data.Elements = value
      this.queryData = data
      return data
    },

    // 色斑图
    async drawgdybImg(Peak,item) {
      const { section, color } =  getColorGuide(Peak,item.colorKey); // 更改可以值 注意需要颜色生成方法的key
      if(item.colorKey == 'wendu'){
          this.sbttl = drawCard1(section, color, 15);
      }else{
        this.sbttl = drawCard(section, color, 15);
      }
      // console.log(this.sbttl)
      // mapLayerr = this.tdMap.imageLayerr(Range, img);
      // this.$loading.hide()
    },

    async GetDataSbtQuery() {
      const obj = {
        Station: this.rainfallsite,
        Start: Formats(this.rainfall.startTime),
        End: Formats(this.rainfall.endTime),
        Type: this.rainfallType,
      }
      this.overlayLoading = true
      const res = await GetDataSbtQuery(obj)
      this.sebantudata = res.data
      this.overlayLoading = false
    },


    // 要素列表
    selElement(el){
      this.elementList.forEach(el=>{
          el.open = false;
      });
      el.open = true;
      let element = this.getSelElement(this.elementList);
      this.predictionItem = element;
      this.selItemElement(element.list[0], element)
    },

    // 要素分支
    selItemElement(el,item){
      // el子节点 item 父节点
      item.list.forEach((it=>{it.open = false}))
      el.open = true;
      let unit = this.partUnit(item.tid).dw;
      this.actuality.elementText = `${el.name}（${unit}）`
      this.actuality.Isdowm = false;
      var params = this.defineTime(Object.assign({}, el, item)) // 获取参数
      if (mapLayerr) this.cleanMapLayerr();
      this.sbttl = null;
      this.getData(params, item, el)
    },


    // 获取数据
    async getData(params, item, el) {
      this.$loading.show()
      const { data, code } = await Get_DataQuery(params)
      this.$loading.hide()
      if (code == 'ok') {
        let errs = [null, '', undefined];
        if (errs.includes(data.Data)) data.Data = [];
        const { Data } = data;
        var Start = Formats(new Date(params.StartTime), 'dd日HH时');
        var end = Formats(new Date(params.EndTime), 'dd日HH时');
        this.setupTitleText(Start, end, el);
        this.copyTotalData = Data;
        this.elTypeItem = item;
        let Peak = this.getDataPeak(Data); 
        this.drawgdybImg(Peak,item);
        if (Data.length != 0) this.drawLayer(Peak,Data);
        this.AnnotateSiteData(Data)
      }
    },

    // 添加站点标注
    AnnotateSiteData(Data){
      let siteClick = (e)=>{
        this.weatherData = {};
        this.getgdybFunEc(e.target.De.label.data)
        this.facePlate = true;
      }
      markers = Data.sort((a,b)=>b.data - a.data).map((site,index)=>{
        let img = 'bluesite.png'
        if(index=== 0){img = 'yellowsite.png'}
        let url = require(`@/assets/images/${img}`);
        let { Lon, Lat, StationName } = site;
        let marker = mytdMap.addSiteMark(site, Lon, Lat, url, StationName, siteClick);
        return marker
      })
      // console.log(markers)
    },

    // 更新title
    setupTitleText(Start, end, el){
      // let time = `${Start}至${end}`;
      let time = `${end}`;
      let text = el.name
      this.actuality.title = `邯郸市${text}（${time}）`;
    },

    // 绘制色斑图
    drawLayer(Peak,data) {
      var colorKey = this.elTypeItem.colorKey
      var boundaries = require('@/assets/Facejson.json');
      var scope = {
        minlng: 113.46539037726474,
        maxlng: 115.48357359296629,
        minlat: 36.04820207889178,
        maxlat: 37.01507440960651,
      }
      this.canvasImg = loadkriging(data, boundaries, colorKey, scope, Peak);
      this.picturesimageLayerr(this.canvasImg,scope)
    },

    // 获取数据极值
    getDataPeak(data){
      if(data.length == 0) return {min:0,max:0}
      let arr = JSON.parse(JSON.stringify(data));
      let ssArr = arr.map(it=>it.data).sort((a,b)=>b - a);
      // console.log('数据：',ssArr)
      return {min:ssArr[ssArr.length-1], max:ssArr[0] }
    },

    // 贴色斑图
    picturesimageLayerr(img,scope){
    const { maxlat, maxlng, minlat, minlng, } = scope;
      let Range = { maxlat: maxlat, maxlng: maxlng, minlat: minlat, minlng: minlng };
      if (mapLayerr) this.cleanMapLayerr();
      mapLayerr = tdMap.imageLayerr(Range, img);
    },

    // 获取选中的要素
    getSelElement(list){
      if(!Array.isArray(list)) return false
      for (const element of list) {
        if(element.open) return element
      }
    },

    /**清除覆盖物 */
    cleanMapLayerr(isOpen) {
      mytdMap.map.remove(mapLayerr); //清除所有覆盖物
      // if (isOpen) $('#gdybElType').children().removeClass('elTypeActive');
      this.clearMapMark()
    },

    /**清除站点标记 */
    clearMapMark(){
      mytdMap.clearmarkers(markers); //清除所有覆盖物
    },


    // 对象数组深拷贝
    objDeepCopy: function (source) {
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        sourceCopy[item] =
          typeof source[item] === 'object'
            ? this.objDeepCopy(source[item])
            : source[item]
      }
      return sourceCopy
    },

    // 要素单位
    partUnit(key) {
      var TEM = ['wendu','TMP'].includes(key)
      var WIN = ['fsfx','fengxiang','EDA10'].includes(key)
      var PRE = ['jiangshui', 'jiangyu', 'ER03'].includes(key)
      var SHD = ['shidu','ERH'].includes(key)
      var NJD = ['njd'].includes(key)
      var QYH = ['qiya'].includes(key)
      if (TEM) return { dw: '℃' }
      else if (WIN) return { dw: 'm/s' }
      else if (PRE) return { dw: 'mm' }
      else if (SHD) return { dw: '%' }
      else if (NJD) return { dw: 'km' }
      else if (QYH) return { dw: 'hpa' }
      else return { dw: '' }
    },


    // 地图创建
    mapCreat() {
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.map = new AMap.Map("wgybMap1", {
        center: [mapCenter[0], mapCenter[1]],
        resizeEnable: true,
        expandZoomRange: true,
        zoomEnable: true,
        scrollWheel: true,
        zoom: 8,
      });
      
      tdMap.drawBounds();
      // tdMap.map.on("zoomchange", function () {
      //   console.log("zoomchange");
      //   if (MyLayers.gridLayer) MyLayers.gridLayer.reset();
      // });
      setTimeout(() => {
        this.selElement(this.elementList[0])
      }, 1500);
      // this.drawgdyb(this.elementList[0]);
      mytdMap = tdMap;
      mytdMap.addAreaLine(230200, 3);
      mytdMap.map.on("click", (ev) => {
        console.log(ev);
        var { lat, lng } = ev.lnglat;
        // this.search = lng+','+lat
        // this.coordinate = { lat, lng };
        // mytdMap.clearMark(); // 清除标注点
        // mytdMap.addMark(lng, lat, require("../../assets/dianwei.png"), ""); // 添加标注点
        // this.facePlate = true
        // this.EcGeDianData();
      });
    },
  },
};
</script>
<style>
  .metar_box .amap-maps .amap-marker-label{
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  .metar_box .AMap-markerNum,
  .metar_box .AMap-markerText{
    -webkit-text-fill-color: #fff;
    font-size: 12px;
    font-weight: 900;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #333;
    transform: translate(5px,0px);
  }
  .metar_box .AMap-markerNum{
    transform: translate(0px,-43px);
  }
</style>

<style lang="scss" scoped>

/* 进入动画 */
.moveCartoon-enter-active {
    animation: move 0.3s;
    
}

/* 离开动画 */
// .moveCartoon-leave-active {
//     animation: move 0s reverse;
// }

@keyframes move {
  0% {transform: scaleY(0);transform-origin:50% 0%;opacity: 0;}
  100% { transform: scaleY(1);transform-origin:50% 0%;opacity: 1;}
}

.metar_box {
  position: relative;

  .op_layer {
    position: absolute;
  }

  .openSebantu {
    color: #fff !important;
    background-color: rgba(35, 97, 206, 0.9) !important;
  }

  .Tips {
    color: #333;
    font-size: 14px;
    text-align: center;
  }

  .sbttl {
    bottom: 2%;
    right: 1%;
    color: #333;
    display: flex;
    flex-direction: column;
    span{
      font-size: 14px;
    }
  }
  .grid_time {
    display: flex;
    color: #333;
    border: 1px solid #ccc;
    margin: 0 10px;

    li:not(:last-child) {
      border-right: 1px solid #ccc;
    }

    li {
      width: 33%;
      text-align: center;

      p:first-child {
        font-size: 16px;
        color: #13a9ec;
      }

      p {
        margin: 8px 0;
        font-size: 13px;
      }
    }
  }
  .activeElmenu{
    color: #00adef;
  }
  .yscdan_box{
    display:flex;
    align-items: center;
    border-top: 1px solid #E6E6E6;
        justify-content: space-around;
    .sijda{
      // width: 85%;
      color: #333;
      display: flex;
      
      li{
        padding: 2px 5px;
        border-right: 1px solid #E6E6E6;
      }
    }
    .sijda-danwei{
      width: 18%;
      color: #333;
      font-size: 14px;
      text-align: right;
    }
  }


  .ec_data {
    width: 95%;
    height: 210px;
    margin: auto;
  }


  .close_panel {
    padding: 5px 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #333;

    h3 {
      margin: 0;
    }

    span {
      font-size: 13px;
      color: #c9c9c9;
    }

    i {
      font-size: 26px;
      padding: 0 10px;
    }
  }

  .choice_time {
    right: 2%;
    top: 15%;
    font-size: 16px;

    .date_item {
      color: #13a9ec;
      font-weight: bold;
      padding: 10px 15px;
      background-color: #fff;
      box-shadow: 2px 3px 5px #888888;
    }

    .time_ul {
      height: 250px;
      overflow: auto;
      margin-top: 3px;
      border: 1px solid #628ff3;
      border-radius: 3px;
      padding: 5px 10px;
      background-color: #fff;

      li {
        margin: 10px 0;
        color: #333;
      }
    }
  }

  
  .search {
    top: calc(40px + 1%);
    left: 3%;
    // transform: translateX(-50%);
    // width: 10%;
    // height: 200px;
    // background-color: #fff;
    // border-radius: 5px;
    // overflow: hidden;
    // box-shadow: 2px 2px 3px rgba(201,201,201, 0.9);
  }
  .Article{
    .Article_item{
      .article_title > span,
      .Article_item_fact > span{
        color: #669CC3;
      }
    }
  }
  .element_Box1{
    // width: 100px;
    padding: 5px;
    background-color: #fff;
        height: auto;
    transition: all 0.5s;
    .article_title{
      display: flex;
      align-items: center;
    }
  }
  .element_Box2{
    padding: 5px;
    margin-top: 20px;
    display: inline-block;
      background-color: #fff;
  }
  .element_list {
    left: 2%;
    top: 15%;
    color: #979797;
    border-bottom: 1px solid #E5E3E3;
    .fact_list{
      border-top:  1px solid #E5E3E3;
      li{
        font-size: 15px;
        margin: 5px 0;
      }
      li:before{
        content: ' ';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #AFD2F0;
        margin: 0 5px;
      }
    }
    .switch {
      background-color: rgba(35, 97, 206, 0.9) !important;

      > div {
        color: #fff !important;
      }
    }

    .element_img {
      // display: block;
      width: 40px;
      // margin: auto;
    }

    .element_item {
      position: relative;
      border-radius: 10px;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      margin-left: 10px;
      // background-color: rgba(35,97,206,.9);
      background-color: rgba(234, 243, 252, 0.9);

      > div {
        white-space: nowrap;
        font-size: 12px;
        text-align: center;
        color: #628ff3;
      }

      .wendu_box {
        position: absolute;
        top: 0%;
        left: 50px;
        display: flex;
      }
    }

    > ul > .element_item {
      margin: 10px 0;
    }
  }

  .isSebantu {
    right: 3%;
    top: calc(40px + 1%);
    border-radius: 5px;
    width: 80px;
    height: 40px;
    background-color: rgba(234, 243, 252, 0.9);
    color: #628ff3;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    box-shadow: 2px 3px 5px #888888;
  }

  .title {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background-color: #628ff3;
    text-align: center;

    p {
      line-height: 40px;
      margin: 0;
    }
  }


  .item_sbt_options {
    left: calc(100px + 5%);
    top: calc(40px + 40px + 2%);
    width: 200px;
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 3px 5px #888888;
    border-radius: 5px;
    ul{
      .activeelement{
        color: #00adef;
      }
      li{
        padding: 5px 10px;
      }
    }
  }

  .sbt_options {
    left: 3%;
    top: calc(40px + 40px + 2%);
    width: 100px;
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 3px 5px #888888;
    border-radius: 5px;
    ul{
      .activeelement{
        color: #00adef;
      }
      li{
        padding: 5px 10px;
      }
    }
  }

  /**搜索框样式 */
  .search_content {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background-color: #fff;
    p {
      line-height: 30px;
      margin: 0px;
      color: #00adef;
    }
  }
  .icon-arrow-down {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    height: 18px;
    transition: all 0.3s;
  }
  .arrowDown {
    transform: translateY(-50%) rotate(180deg);
  }
}
</style>
