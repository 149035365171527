
import echarts from "@/utils/echarts";
import { Formats, Format, getweektime } from "@/utils/common.js"
import { Getfengxiang } from "@/utils/Getfengxiang.js"
export function gdybEc(dom,params){
  const { unit, text, xData, series, id } = params;
  var rateChart = echarts.init(dom);
  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (val, index) {
        var time = val[0].axisValue;
        if (id === 'fsfx') {
          var a = val.map(it => ` ${it.seriesName}：${it.data} ${unit}<br />`).join(' ');
          var fx = Getfengxiang(series[0].fxData[val[0].dataIndex]);
          return time + '<br />' + a + ' 风向：'+ fx
        } else {
          var a = val.map(it => ` ${it.seriesName}：${it.data} ${unit}<br />`).join(' ');
          return time + '<br />' + a
        }
        
      }
    },
    grid: {
      top: 30,
      bottom: 50,
      left:50,
    },
        dataZoom: [
      //给x轴设置滚动条
      {
        height: 3,
        start: 0, //默认为0
        end: 100 - 1500 / 31, //默认为100
        type: "slider",
        show: true,
        xAxisIndex: [0,1],
        handleSize: 0, //滑动条的 左右2个滑动条的大小
        bottom: "0%",
        left: "90%", //左边的距离
        right: "90%",
        borderColor: "#000",
        fillerColor: "#01DFA5",
        borderRadius: 5,
        backgroundColor: "#33384b", //两边未选中的滑动条区域的颜色
        showDataShadow: false, //是否显示数据阴影 默认auto
        showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
        realtime: true, //是否实时更新
        filterMode: "filter",
      },
      {
        type: "inside",
        show: true,
        xAxisIndex: [0,1],
        start: 0, //默认为1
        end: 100 - 1500 / 31, //默认为100
      },
  ],
    xAxis: [
      {
        type: 'category',
        
        splitLine:{
          show:true,
        },
        
        axisLabel:{
          formatter: function (value) {
            var a = new Date(value).getHours();
            return a
          },
        },
        data: xData 
      },
    ],
    yAxis: [
      {
        type: 'value',
        // position: 'left',
        axisLabel: {
          fontSize: 10,
          formatter: '{value}' + unit
        }
      },
    ],
    series: series
  };
  
  rateChart.setOption(option);
  return rateChart
}


