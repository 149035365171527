
import request from "@/utils/request.js";

// GET /api/Wechat/Get_StationSk
// 根据站点编号获取近72小时数据
export function Get_StationSk(params) {
  return request({
    url: "/api/Wechat/Get_StationSk",
    method: "get",
    params
  });
}

// GET /api/Wechat/GetDataSbtQuery
// 在线绘制色斑图
export function GetDataSbtQuery(params) {
  return request({
    url: "/api/Wechat/GetDataSbtQuery",
    method: "get",
    params
  });
}

export function tqybweather(params) {
    return request({
      url: "http://www.nmc.cn/rest/weather",
      method: "GET",
      params
    });
}

// GET /api/Wechat/Get_DataQuery   数据统计2
export function Get_DataQuery(params) {
  return request({
    url: "/api/Wechat/Get_DataQuery",
    method: "get",
    params
  });
// return {
//   "code": "ok",
//   "msg": "获取成功",
//   "data": {
//     "Data": [
//       {
//         "Areanmae": "涉县",
//         "StationNo": "53886",
//         "StationName": "涉县",
//         "StationName2": "涉县-涉县",
//         "StationName3": "涉县(53886)",
//         "Lon": "113.6722",
//         "Lat": "36.5675",
//         "data": 38.9,
//         "data2": 294,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "武安市",
//         "StationNo": "53890",
//         "StationName": "武安",
//         "StationName2": "武安市-武安",
//         "StationName3": "武安(53890)",
//         "Lon": "114.1",
//         "Lat": "36.6667",
//         "data": 37.4,
//         "data2": 174,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "永年区",
//         "StationNo": "53895",
//         "StationName": "永年",
//         "StationName2": "永年区-永年",
//         "StationName3": "永年(53895)",
//         "Lon": "114.4558",
//         "Lat": "36.7475",
//         "data": 36.9,
//         "data2": 174,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "峰峰矿区",
//         "StationNo": "53894",
//         "StationName": "峰峰",
//         "StationName2": "峰峰矿区-峰峰",
//         "StationName3": "峰峰(53894)",
//         "Lon": "114.2",
//         "Lat": "36.4",
//         "data": 36.7,
//         "data2": 174,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "馆陶县",
//         "StationNo": "54809",
//         "StationName": "馆陶",
//         "StationName2": "馆陶县-馆陶",
//         "StationName3": "馆陶(54809)",
//         "Lon": "115.2833",
//         "Lat": "36.55",
//         "data": 35.7,
//         "data2": 217,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "邯郸经济技术开发区",
//         "StationNo": "53892",
//         "StationName": "邯郸",
//         "StationName2": "邯郸经济技术开发区-邯郸",
//         "StationName3": "邯郸(53892)",
//         "Lon": "114.4647",
//         "Lat": "36.625",
//         "data": 35.6,
//         "data2": 168,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "鸡泽县",
//         "StationNo": "54640",
//         "StationName": "鸡泽",
//         "StationName2": "鸡泽县-鸡泽",
//         "StationName3": "鸡泽(54640)",
//         "Lon": "114.8728",
//         "Lat": "36.8903",
//         "data": 35.5,
//         "data2": 183,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "磁县",
//         "StationNo": "53897",
//         "StationName": "磁县",
//         "StationName2": "磁县-磁县",
//         "StationName3": "磁县(53897)",
//         "Lon": "114.3428",
//         "Lat": "36.4006",
//         "data": 35.3,
//         "data2": 205,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "广平县",
//         "StationNo": "53899",
//         "StationName": "广平",
//         "StationName2": "广平县-广平",
//         "StationName3": "广平(53899)",
//         "Lon": "114.9667",
//         "Lat": "36.4833",
//         "data": 35.1,
//         "data2": 200,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "曲周县",
//         "StationNo": "53893",
//         "StationName": "曲周",
//         "StationName2": "曲周县-曲周",
//         "StationName3": "曲周(53893)",
//         "Lon": "115.005",
//         "Lat": "36.7889",
//         "data": 35,
//         "data2": 194,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "成安县",
//         "StationNo": "53996",
//         "StationName": "成安",
//         "StationName2": "成安县-成安",
//         "StationName3": "成安(53996)",
//         "Lon": "114.65",
//         "Lat": "36.45",
//         "data": 34.9,
//         "data2": 179,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "邯郸市",
//         "StationNo": "53980",
//         "StationName": "肥乡",
//         "StationName2": "邯郸市-肥乡",
//         "StationName3": "肥乡(53980)",
//         "Lon": "114.8075",
//         "Lat": "36.5408",
//         "data": 34.6,
//         "data2": 180,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "临漳县",
//         "StationNo": "53773",
//         "StationName": "临漳",
//         "StationName2": "临漳县-临漳",
//         "StationName3": "临漳(53773)",
//         "Lon": "114.595",
//         "Lat": "36.2986",
//         "data": 34.1,
//         "data2": 162,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "邱县",
//         "StationNo": "54820",
//         "StationName": "邱县",
//         "StationName2": "邱县-邱县",
//         "StationName3": "邱县(54820)",
//         "Lon": "115.2078",
//         "Lat": "36.81",
//         "data": 34.1,
//         "data2": 200,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "大名县",
//         "StationNo": "54804",
//         "StationName": "大名",
//         "StationName2": "大名县-大名",
//         "StationName3": "大名(54804)",
//         "Lon": "115.1814",
//         "Lat": "36.3042",
//         "data": 34,
//         "data2": 236,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       },
//       {
//         "Areanmae": "魏县",
//         "StationNo": "53896",
//         "StationName": "魏县",
//         "StationName2": "魏县-魏县",
//         "StationName3": "魏县(53896)",
//         "Lon": "114.9494",
//         "Lat": "36.3278",
//         "data": 33.3,
//         "data2": 178,
//         "type": 1,
//         "time": "2023/07/06 14:00"
//       }
//     ],
//     "Level": [
//       {
//         "Level": "35℃以上",
//         "Note": "涉县,武安,永年,峰峰,馆陶,邯郸,鸡泽,磁县,广平",
//         "NoteS": "涉县,武安市,永年区,峰峰矿区,馆陶县,邯郸经济技术开发区,鸡泽县,磁县,广平县",
//         "Count": 9
//       },
//       {
//         "Level": "30-35℃",
//         "Note": "曲周,成安,肥乡,临漳,邱县,大名,魏县",
//         "NoteS": "曲周县,成安县,邯郸市,临漳县,邱县,大名县,魏县",
//         "Count": 7
//       },
//       {
//         "Level": "20-30℃",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       },
//       {
//         "Level": "10-20℃",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       },
//       {
//         "Level": "0-10℃",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       },
//       {
//         "Level": "-10~0℃",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       },
//       {
//         "Level": "-20~-10℃",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       },
//       {
//         "Level": "-20℃以下",
//         "Note": "无",
//         "NoteS": "无",
//         "Count": 0
//       }
//     ],
//     "Describe": "温度:2023-07-06 13:00至2023-07-06 14:00<br/>最大温度出现在涉县-涉县站,温度为38.9摄氏度,最低温度出现在魏县-魏县站,温度为33.3摄氏度。"
//   }
// }
}